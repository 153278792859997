* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: 'Charlie Text', sans-serif;
}

#root {
  height: 100%;
}
